body {
    font-size: var(--font-size-medium);
}

a {
    text-decoration: none;
    color: inherit;
}

.base {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;

    .navbar {
        flex-shrink: 0;

        &.hidden {
            display: none;
        }
    }

    .view,
    .init {
        flex-grow: 1;
        overflow: auto;
    }
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

p {
    margin: 0;
}

:root {
    --spacing-super-small: 0.1rem;
    --spacing-extra-small: 0.2rem;
    --spacing-small: 0.4rem;
    --spacing-medium: 0.8rem;
    --spacing-large: 1.6rem;
    --spacing-extra-large: 2.8rem;
    --spacing-super-large: 4.4rem;

    --color-background: #f3f3f3;
    --color-foreground: #ffffff;
    --color-backdrop: rgba(0, 0, 0, .7);
    --color-backdrop-light: rgba(255, 255, 255, .7);
    --color-background-primary-hint: #0d194920;
    --color-background-accent-hint: #4746f610;

    --color-black: #000000;

    --color-scrollbar-foreground: rgba(0, 0, 0, .2);
    --color-scrollbar-background: rgba(0, 0, 0, .01);
    --color-hover-background: rgba(0, 0, 0, 0.03);
    --color-input-background: #4746f605;

    --color-primary: #0d1949;
    --color-primary-light: #2d5989;
    --color-text-on-dark: #ffffff;
    --color-text-watermark: rgba(0, 0, 0, .4);
    --color-text: rgba(0, 0, 0, 0.8);
    --color-text-dark: rgba(0, 0, 0, 1);
    --color-text-light: rgba(0, 0, 0, 0.6);
    --color-text-gray: #666666;
    --color-accent: #4746f6;
    --color-danger: #e04656;
    --color-success: #53b391;
    --color-banner-alert: #fdf4dc;
    --color-banner-text: #5C4813;
    --color-separator: rgba(0, 0, 0, 0.1);
    --color-shadow: rgba(0, 0, 0, 0.2);

    --color-input-border: rgba(0, 0, 0, 0.2);
    --color-active-input-border: var(--color-accent);

    --font-size-small: 0.78rem;
    --font-size-medium: 0.85rem;
    --font-size-large: 1.125rem;
    --font-size-extra-large: 1.5rem;
    --font-size-super-large: 2rem;
    --font-size-mega-large: 2.75rem;
    --font-size-ultra-large: 4rem;

    --font-weight-medium: 400;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;

    --opacity-disabled-element: 0.5;

    --width-separator-thin: 1pt;
    --width-separator-thick: 5pt;
    --width-separator-mobile-preview: 8pt;
    --width-scrollbar: 0.75rem;
    --width-page-content-max: 70rem;
    --width-navbar-content-max: 76rem;

    --width-mobile-preview: 22rem;
    --height-mobile-preview: 40rem;
    --height-mobile-preview-builarea-content: 30rem;
    --height-mobile-preview-footprint-content: 22rem;
    --height-mobile-preview-change-detection-content: 14rem;

    --radius-popup-border: 0.25rem;
    --radius-scrollbar-border: 0.25rem;
    --radius-blur-shadow: 5px;
    --radius-modal: 0.25rem;
    --radius-button-border: 0.25rem;
    --radius-input-border: 0.25rem;
    --radius-card-border: 0.5rem;
    --radius-badge-border: 1rem;

    --line-height-none: 1;
    --line-height-tight: 1.25;
    --line-height-snug: 1.375;
    --line-height-normal: 1.5;
    --line-height-relaxed: 1.625;
    --line-height-loose: 2;

    --shadow-card: 0 2px 4px -2px var(--color-shadow);

    --duration-transition-medium: .2s;

    --color-background-hover-light: rgba(0, 0, 0, .04);
    --width-calendar-date: 2.4rem;

    --opacity-watermark: 0.3;
    --color-text-disabled: rgba(0, 0, 0, .3);

}
