.modal {
    display: flex;
    flex-direction: column;
    border: var(--width-separator-thin) solid var(--color-separator);
    border-radius: var(--radius-modal);
    background-color: var(--color-foreground);
    overflow: auto;
    color: var(--color-text);
    gap: var(--spacing-large);

    .modal-header {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        .heading {
            flex-grow: 1;
            font-size: var(--font-size-large);
        }

        .actions {
            flex-shrink: 0;

            .close-button {
                padding: calc(var(--spacing-small) - var(--spacing-extra-small));
                font-size: var(--font-size-large);
            }
        }
    }

    .modal-body {
        flex-grow: 1;
        overflow: auto;
    }

    .modal-footer {
        flex-shrink: 0;
    }
}
