.navbar {
    display: flex;
    justify-content: center;
    z-index: 1;
    box-shadow: 0 3px 5px -2px var(--color-shadow);
    background-color: var(--color-primary);
    padding: var(--spacing-medium) var(--spacing-large);
    color: var(--color-text-on-dark);

    .container {
        display: flex;
        position: relative;
        align-items: center;
        flex-grow: 1;
        flex-wrap: wrap;
        max-width: var(--width-navbar-content-max);
        gap: var(--spacing-large);

        .app-brand {
            display: flex;
            align-items: center;
            flex-shrink: 0;

            .logo {
                height: 2.6rem;
            }
        }

        .main {
            display: flex;
            align-items: center;
            align-self: stretch;
            flex-grow: 1;
            overflow: auto;

            .nav-links {
                display: flex;
                flex-grow: 1;
                justify-content: flex-start;
                font-size: var(--font-size-large);
                gap: var(--spacing-large);

                .link {
                    opacity: 0.7;
                    color: var(--color-text-on-dark);

                    &.active {
                        opacity: 1;
                        font-weight: var(--font-weight-bold);
                    }
                }
            }
        }

        .user-details {
            display: flex;
            align-items: center;
            gap: var(--spacing-medium);

            .logout-button {
                color: var(--color-text-on-dark);
            }
        }
    }
}
