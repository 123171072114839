.smart-nav-link {
    display: flex;
    position: relative;
    align-items: center;
    outline: none;
    text-decoration: none;
    color: var(--color-text);

    &.active {
        color: var(--color-accent);
    }

    &:hover {
        background-color: var(--color-hover-background);
    }
}
