.body-backdrop {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background-color: var(--color-backdrop);
    width: 100vw;
    height: 100vh;
}
