.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    background-color: var(--color-background);

    .heading {
        color: var(--color-danger);
        font-size: var(--font-size-super-large);
        font-weight: var(--font-weight-bold);
    }

    .message {
        margin: var(--spacing-large) 0;
        text-align: center;
        font-size: var(--font-size-large);
    }
}
